import React from "react";
import Layout from "../components/Layout";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import '../styles/404.css'

export default function Home() {

  return (
    <Layout>
        <Helmet>
            <title>404 - Page Not Found</title>
            <meta name="description" content="Page not found." />
        </Helmet>
        <div id="notfound">
            <div class="notfound">
                <h1>404</h1>
                <h2>We're sorry, this page cannot be found.</h2>
                <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
                <Link to="/">Back To Homepage</Link>
            </div>
        </div>
    </Layout>
  )
};